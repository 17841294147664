.Container {
  background: #101010;
}

.Wrap {
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
}

.LinksWrapper {
  display: flex;
}

.LinksContainer {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 820px) {
  .LinksWrapper {
    flex-direction: column;
  }
  .LinksContainer {
    padding-top: 32px;
  }
  .SocialMediaWrap {
    flex-direction: column;
  }
}

.LinkItems {
  display: flex;
  flex-direction: column;
  margin: 16px;
  text-align: left;
  width: 200px;
  box-sizing: border-box;
  color: #eee;
}
@media screen and (max-width: 420px) {
  .LinkItems {
    padding: 20px;
    margin: 0;
    width: 100%;
  }
}
.LinkTitle {
  margin-bottom: 16px;
  font-size: 24px;
  text-align: left;
}

.SocialMedia {
  align-items: center;
  justify-self: start;
  width: 100%;
}

.SocialMediaWrap {
  display: flex;
  justify-content: space-between;
  justify-self: start;
  align-items: center;
  max-width: 80%;
  margin: 40px auto 0 auto;
}

.SocialLogo {
  color: #eee;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}
.SocialLogo:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-shadow: -1px -1px 3px #7e42ff, 1px -1px 3px #7e42ff, -1px 1px 3px #7e42ff,
    1px 1px 3px #7e42ff;
}

.WebsiteRights {
  color: #eee;
  margin-bottom: 16px;
}
.SocialIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.SocialIconLink {
  color: #eee;
  font-size: 24px;
  transition: all 0.3s ease-in-out;
}
.SocialIconLink:hover {
  color: #7e42ff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
