/* aside */
.SidebarContainer {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #101010;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
}

.FaTimes {
  color: #eee;
}

/* div */
.Icon {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
}

.SidebarMenu {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;
}
@media screen and (max-width: 480px) {
  .SidebarMenu {
    grid-template-rows: repeat(6, 60px);
  }
}
