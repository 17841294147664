/* div */
.TileContainer {
  height: 1300px;
  display: flex;
  flex-direction: column;
  background: #727d71;
}

.CodecTileContainer {
  height: 1050px;
  display: flex;
  flex-direction: column;
  background: #727d71;
}

/* div */
.TileWrapper {
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;
}
@media screen and (max-width: 1000px) {
  .TileContainer {
    height: 1950px;
  }
  .CodecTileContainer {
    height: 1300px;
  }
  .TileWrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 700px) {
  .TileContainer {
    height: 3350px;
  }
  .CodecTileContainer {
    height: 2350px;
  }
  .TileWrapper {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
}

/* div */
.TileCard {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  height: 340px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}
.TileCard:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  z-index: 1;
}

/* div */
.CodecTileCard {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  height: 200px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}
.CodecTileCard:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  z-index: 1;
}

/* img */
.TileImg {
  height: 100px;
  width: 160px;
  margin-bottom: 10px;
}

/* h1 */
.TileH1 {
  font-size: 2.5rem;
  color: #101010;
  font-weight: 300;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 64px;
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }
}
/* h2 */
.TileH2 {
  font-size: 1rem;
  margin-bottom: 10px;
}

/* p */
.TileP {
  font-size: 1rem;
  text-align: center;
}
