* {
  /* font-family: "VT323", monospace; */
  font-family: "PT Mono", monospace;
  font-weight: 100;
}

p,
h2 {
  font-weight: 500;
}
