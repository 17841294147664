.loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #101010;
  z-index: 1000;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
