/* applies to div */
.ArticleHeroContainer {
  background: #101010;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  position: relative;
  z-index: 1;
}
/* applies to div */
.HeroBg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: inherit;
  overflow: hidden;
}
/* applies to video */
.VideoBg {
  height: inherit;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #101010;
  filter: brightness(80%);
}

.CodecBg {
  height: inherit;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #101010;
  filter: brightness(70%);
}
.ArticleHeroBg {
  height: inherit;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #101010;
  filter: brightness(120%);
}

/* applies to div */
.HeroContent {
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* applies to h1 */
.HeroH1 {
  color: #eee;
  font-size: 48px;
  text-align: center;
  text-shadow: 0 0 10px #101010;
}
/* applies to p */
.HeroP {
  margin-top: 24px;
  color: #eee;
  font-size: 34px;
  text-align: center;
  max-width: 600px;
  text-shadow: 0 0 10px #101010;
}

@media screen and (max-width: 768px) {
  .HeroH1 {
    font-size: 40px;
  }
  .HeroP {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .HeroH1 {
    font-size: 32px;
  }
  .HeroP {
    font-size: 18px;
  }
}
