.HeroButton {
  border-radius: 50px;
  background: #010606;
  white-space: nowrap;
  padding: 14px 48px;
  color: #eee;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}
.HeroButton:hover {
  transition: all 0.2s ease-in-out;
  box-shadow: -1px -1px 3px #7e42ff, 1px -1px 3px #7e42ff, -1px 1px 3px #7e42ff,
    1px 1px 3px #7e42ff;
  color: #fff;
  font-weight: 600;
}

.HeroButtonWrapper {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Arrow {
  margin-left: 8px;
  font-size: 20px;
}

.GeneralLink {
  color: #eee;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-shadow: -1px -1px 4px #000, 1px -1px 4px #000, -1px 1px 4px #000,
    1px 1px 4px #000;
}
.GeneralLink:hover {
  color: #fff;
  transition: all 0.2s ease-in-out;
  text-shadow: -1px -1px 6px #7e42ff, 1px -1px 6px #7e42ff, -1px 1px 6px #7e42ff,
    1px 1px 6px #7e42ff;
  border-radius: 50px;
}

.FooterLink {
  color: #eee;
  text-decoration: none;
  text-align: left;
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 14px;
}
.FooterLink:hover {
  color: #fff;
  text-shadow: -1px -1px 3px #7e42ff, 1px -1px 3px #7e42ff, -1px 1px 3px #7e42ff,
    1px 1px 3px #7e42ff;
  cursor: pointer;
  transition: 0.3s ease-out;
}

.Logo {
  font-size: 30px;
  font-weight: 500;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #eee;
  text-decoration: none;
  text-shadow: -1px -1px 3px black, 1px -1px 3px black, -1px 1px 3px black,
    1px 1px 3px black;
  transition: all 0.5s ease-in-out;
}
.Logo:hover {
  cursor: pointer;
  color: #fff;
  transition: all 0.5s ease-in-out;
  text-shadow: -1px -1px 3px #7e42ff, 1px -1px 3px #7e42ff, -1px 1px 3px #7e42ff,
    1px 1px 3px #7e42ff;
}

.WideButton {
  border-radius: 50px;
  padding: 14px 48px;
  white-space: nowrap;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  color: #eee;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  transition: all 0.2s ease-in-out;
  background: #101010;
  border-style: solid;
  border-width: 1px;
  font-weight: 300;
}
.WideButton:hover {
  transition: all 0.2s ease-in-out;
  border-style: hidden;
  color: #eee;
  font-weight: 600;
  box-shadow: -1px -1px 3px #7e42ff, 1px -1px 3px #7e42ff, -1px 1px 3px #7e42ff,
    1px 1px 3px #7e42ff;
}
