/*div*/
.InfoSectionContainerLight {
  color: #101010;
  align-items: center;
  background-color: #eee;
}

/*div*/
.InfoSectionContainerDark {
  color: #eee;
  align-items: center;
  background-color: #101010;
}

@media screen and (max-width: 810px) {
  .InfoSectionContainerDark {
    padding-top: 10px;
    padding-bottom: 350px;
  }
  .InfoSectionContainerLight {
    padding-top: 10px;
    padding-bottom: 350px;
  }
}

/* div */
.InfoSectionRowImgStart {
  display: grid;
  grid-auto-columns: minmax(auto, 0.8fr);
  padding: 20px;
  align-items: center;
  grid-template-areas: "img txt";
}

/* div */
.InfoSectionRow {
  display: grid;
  text-align: right;
  grid-auto-columns: minmax(auto, 0.8fr);
  align-items: center;
  grid-template-areas: "txt img";
}
/* div */
.Column1 {
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: txt;
}

/* div */
.Column2 {
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: img;
}
/* div */
.ImgWrap {
  max-width: 555px;
  height: 400px;
}

@media screen and (max-width: 810px) {
  .InfoSectionRowImgStart {
    grid-template-areas: "txt" "img";
  }
  .InfoSectionRow {
    grid-template-areas: "txt txt" "img img";
  }
  .ImgWrap {
    max-width: 400px;
    height: 500px;
  }
}

/* div */
.InfoSectionWrapper {
  display: grid;
  z-index: 1;
  height: 700px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
}

/*p*/
.TopLine {
  color: #7e42ff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

/* h1 */
.DarkH1 {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #010606;
}
.LightH1 {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 500;
  color: #eee;
}

@media screen and (max-width: 480px) {
  .LightH1 {
    font-size: 32px;
  }
  .DarkH1 {
    font-size: 32px;
  }
}

/* p */
.Subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

/* img */
.Img {
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
}

/* div */
.TextWrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}
